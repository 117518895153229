import { cookieService } from '@bfa/nextjs-common/services';
import { getFirstParam, isBrowser, isDev } from '@bfa/nextjs-common/utils';
import { NextPageContext } from 'next';
import { v4 as uuid } from 'uuid';

import { SUBSCRIPTION_FLOWS } from '../../constants';
import { Maybe } from '../../gql.generated';
import {
  AdobeExpCookieProps,
  AdobeExpDetailProps,
  AdobeResponseTokenProps,
} from '../../utils/types';
import CookieName, {
  ADOBE_EXPERINCE_COOKIE_SECONDS,
  ADOBE_INDIVIUAL_EXPERIENCE_EXPIRE_MS,
  SESSION_TIMEOUT_SECONDS,
  TTL_24_HOURS_IN_SECONDS,
} from './cookieName';

/**
 * Check query string and set cookies accordingly.
 *
 * @param ctx - The next page context.
 */
export const setCookiesByQuery = (ctx: NextPageContext) => {
  // set landing page id (lpid) cookie
  const lpidFromQuery = ctx.query[CookieName.LPID_COOKIE_NAME];
  const lpid = getFirstParam(lpidFromQuery);
  if (lpid) {
    cookieService.setCookie(ctx, CookieName.LPID_COOKIE_NAME, lpid);
  }
};

/**
 * Automatically set stagepass cookie when working local so you don't need any extra
 * step to make it work as soon as you navigate localhost.staging.ipsy.com.
 *
 * @param ctx - The next page context.
 */
export const setStagepass = (ctx: NextPageContext) => {
  // Only for development environment
  if (isDev) {
    cookieService.setCookie(ctx, 'stagepass', 'glamination', {
      maxAge: 900,
      path: '/',
      domain: '.staging.ipsy.com',
      secure: true,
      httpOnly: false,
      sameSite: 'Lax',
    });
  }
};

export const refreshAdminLogin = (ctx: NextPageContext) => {
  if (isBrowser) return;

  const isAdminCookie = cookieService.getCookie(
    ctx,
    CookieName.IS_ADMIN_LOGIN_AS
  );

  if (isAdminCookie !== 'true') return;

  cookieService.setCookie(ctx, CookieName.IS_ADMIN_LOGIN_AS, 'true', {
    maxAge: SESSION_TIMEOUT_SECONDS,
    path: '/',
    secure: true,
    httpOnly: false,
    sameSite: 'Lax',
  });
};

type AbobeCookieProps = {
  [tid: string]: AdobeExpCookieProps;
};

export const updateAdobeExperienceCookie = (
  ctx: NextPageContext | null | undefined,
  value: AdobeExpDetailProps[]
) => {
  const [tid, pastExperiences] = getPastAdobeExperienceCookieByTid(ctx);

  const currentTime = Date.now();

  const newExperienceData: AdobeExpCookieProps = {};
  //get experience object from adobe event response
  value?.forEach((element: AdobeExpDetailProps) => {
    element.data?.responseTokens.forEach((token: AdobeResponseTokenProps) => {
      const expId = token['activity.id'];
      if (expId) {
        newExperienceData[expId] = {
          lastReceivedDate: currentTime,
          cohortId: token['experience.id'],
        };
      }
    });
  });

  //merge new experience object from adobe event response and past adobe experience from cookie
  const mergedExperiences = { ...pastExperiences, ...newExperienceData };

  //remove experience which was received more than 6 months ago
  for (const activityId in mergedExperiences) {
    const lastReceivedDate = mergedExperiences[activityId].lastReceivedDate;
    if (currentTime - lastReceivedDate > ADOBE_INDIVIUAL_EXPERIENCE_EXPIRE_MS) {
      delete mergedExperiences[activityId];
    }
  }

  cookieService.setCookieWithSharedDomain(
    ctx,
    CookieName.ADOBE_TARGET_IPSY_EXPERIENCE_COOKIE,
    { [tid as string]: mergedExperiences },
    {
      maxAge: ADOBE_EXPERINCE_COOKIE_SECONDS,
    }
  );
};

export const updateAdobeCookie = (
  ctx: NextPageContext | null | undefined,
  value: unknown = ''
) => {
  cookieService.setCookieWithSharedDomain(
    ctx,
    CookieName.ADOBE_TARGET_THIRD_PARTY_COOKIE,
    value
  );
};

export const updateCookie = (
  isSharedDomain: boolean,
  ctx: NextPageContext | null | undefined,
  key: string,
  value: unknown,
  options: any
) => {
  cookieService.removeCookie(ctx, key);
  if (value) {
    if (isSharedDomain)
      cookieService.setCookieWithSharedDomain(ctx, key, value, { ...options });
    else cookieService.setCookie(ctx, key, value, { ...options });
  }
};

export const removeCookie = (
  ctx: NextPageContext | null | undefined,
  key: string
): void => cookieService.removeCookie(ctx, key);

export const updateGvsCookie = (
  ctx: NextPageContext | null | undefined,
  key: string,
  value: boolean,
  maxAge: number
) => {
  if (value !== null)
    cookieService.setCookieWithSharedDomain(ctx, key, value, {
      maxAge,
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'Lax',
    });
};

export const setMembershipConfirmationCookies = (
  successPrograms: string,
  gift?: boolean,
  flow?: SUBSCRIPTION_FLOWS
): void => {
  cookieService.removeCookie(null, CookieName.SUB_CONFIRMATION_SUCCESS_PROGRAM);
  cookieService.setCookieWithSharedDomain(
    null,
    CookieName.SUB_CONFIRMATION_SUCCESS_PROGRAM,
    successPrograms,
    { maxAge: TTL_24_HOURS_IN_SECONDS }
  );
  cookieService.removeCookie(null, CookieName.SUB_CONFIRMATION_GIFT);
  cookieService.removeCookie(null, CookieName.SUB_CONFIRMATION_GIFT_BANNER);
  cookieService.setCookieWithSharedDomain(
    null,
    CookieName.SUB_CONFIRMATION_GIFT,
    gift ?? false,
    {
      maxAge: TTL_24_HOURS_IN_SECONDS,
    }
  );
  if (flow) {
    cookieService.removeCookie(null, CookieName.SUB_CONFIRMATION_FLOW);
    cookieService.setCookieWithSharedDomain(
      null,
      CookieName.SUB_CONFIRMATION_FLOW,
      flow,
      {
        maxAge: TTL_24_HOURS_IN_SECONDS,
      }
    );
  }
};

export const getPastAdobeExperienceCookieByTid = (
  ctx?: NextPageContext | null | undefined
): [string | undefined, AdobeExpCookieProps] => {
  let tid = cookieService.getCookie(ctx, 'ipstr');
  if (!tid && isDev) {
    tid = `itr-${uuid()}`;
    cookieService.setCookie(ctx, 'ipstr', tid);
  }
  if (!tid) return [tid, {}];
  const pastExperiences: AbobeCookieProps =
    cookieService.getCookieAsJson(
      ctx,
      CookieName.ADOBE_TARGET_IPSY_EXPERIENCE_COOKIE
    ) || {};

  return [tid, pastExperiences[tid] || {}];
};

export const setBusinessIdCookie = (
  ctx: NextPageContext | null | undefined,
  businessId: string | null
) => {
  if (businessId) {
    cookieService.setCookie(ctx, CookieName.BUSINESS_ID, businessId);
  }
};

export const setReceivedSubsGiftCardCookie = (
  ctx: NextPageContext | null | undefined,
  giftCardId: string | null
): void => {
  if (giftCardId) {
    cookieService.setCookie(
      ctx,
      CookieName.RECEIVED_SUBS_GIFT_CARD_COOKIE,
      giftCardId
    );
  }
};

/**
 * Get the Cookie to be used on the Gifting Subscription Flow.
 *
 * @param ctx - The next page context.
 *
 */
export const getReceivedSubsGiftCardCookie = (
  ctx?: Maybe<NextPageContext>
): string =>
  cookieService.getCookie(ctx, CookieName.RECEIVED_SUBS_GIFT_CARD_COOKIE) || '';

export const setGiftConfirmationExperienceCookie = (
  giftConfirmationExperience: string
): void => {
  cookieService.removeCookie(null, CookieName.SUB_GIFT_CONFIRMATION_EXP);
  cookieService.setCookieWithSharedDomain(
    null,
    CookieName.SUB_GIFT_CONFIRMATION_EXP,
    giftConfirmationExperience,
    { maxAge: TTL_24_HOURS_IN_SECONDS }
  );
};

export const getGiftConfirmationExperienceCookie = (
  ctx?: Maybe<NextPageContext>
): string =>
  cookieService.getCookie(ctx, CookieName.SUB_GIFT_CONFIRMATION_EXP) || '';
