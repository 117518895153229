import { Config } from '@bfa/next-app-config';
import {
  analyticsService,
  clientConfigurationService,
  clientLogging,
  facebookSdkService,
  forterService,
  mobileBridgeService,
} from '@bfa/nextjs-common/services';
import {
  adobeTarget,
  getDeviceInfo,
  getPathGroup,
  getTeamName,
  isBrowser,
  updateDeviceInfo,
} from '@bfa/nextjs-common/utils';
import { document } from '@bfa/nextjs-common/utils/window';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { FC, useEffect, useRef } from 'react';

import { GQLUserDetails } from '../../gql.generated';
import publishSubccriptionsAmplitude from '../../graphql/utils/publishSubccriptionsAmplitude';
import { getViewName } from '../../utils/adobeTarget';
import { fireDeferredEvents } from '../../utils/analytics';
import {
  isSegmentWhiteListPage,
  segmentPageViewConditionally,
} from '../../utils/segmentAnalytics';

const adobeTargetTriggerViews = (url: string) => {
  /* We have to notify to Adobe Target that a page has changed, 
  calling triggerView function with the view name as parameter.
  To do that we use path of the url and replace '/' by '-'
  Examples:
    /quiz/take/questions => quiz-take-questions
    /glambag/2023/06/FULLSIZE => glambag-2023-06-FULLSIZE
   */
  let pathname = url.split('?')[0];
  pathname = getViewName(pathname);
  if (adobeTarget()?.triggerView)
    adobeTarget().triggerView(pathname, { page: true });
  else
    clientLogging.logError(Error('[AT] - adobeTarget().triggerView undefined'));
};

type Props = {
  user?: Pick<GQLUserDetails, 'id' | 'email' | 'firstName' | 'lastName'>;
};

export const useInitialize = (user?: Props['user']): boolean => {
  const router = useRouter();
  const initialized = useRef<boolean>(false);

  useEffect(() => {
    adobeTargetTriggerViews(window.location.pathname);
  }, []);

  useEffect(() => {
    publishSubccriptionsAmplitude(user?.id);
  }, [router.asPath, user?.id]);

  useEffect(() => {
    router.events.on('routeChangeComplete', adobeTargetTriggerViews);

    return () =>
      router.events.off('routeChangeComplete', adobeTargetTriggerViews);
  }, [router.events]);

  useEffect(() => {
    const fireSegmentPageViewForLoggedInUsers = (url: string) => {
      const pathname = url.split('?')[0];
      //send pageview events only for logged in users and whitelisted url paths
      segmentPageViewConditionally(
        !!user?.id && isSegmentWhiteListPage(pathname),
        pathname
      );
    };
    fireSegmentPageViewForLoggedInUsers(window.location.pathname);
    router.events.on(
      'routeChangeComplete',
      fireSegmentPageViewForLoggedInUsers
    );

    return () =>
      router.events.off(
        'routeChangeComplete',
        fireSegmentPageViewForLoggedInUsers
      );
  }, [router.events]);

  useEffect(() => {
    const trackRumViews = (url: string) => {
      const pathname = url.split('?')[0];
      datadogRum.startView({
        name: getPathGroup(pathname),
        service: getTeamName(pathname),
      });

      document.referrer(window.location.href);
    };

    router.events.on('routeChangeStart', trackRumViews);

    return () => router.events.off('routeChangeStart', trackRumViews);
  }, [router.events]);

  useEffect(() => {
    const requestPlatformInformation = async () => {
      const info = await mobileBridgeService.requestPlatformInformation();
      if (info) {
        updateDeviceInfo({
          ...getDeviceInfo(),
          ...info,
        });
      }

      analyticsService.trackPageView(router);
    };

    if (!initialized.current) {
      datadogRum.init({
        applicationId: Config.public.misc.ddApplicationId,
        clientToken: Config.public.misc.ddClientToken,
        site: 'datadoghq.com',
        service: 'ipsy-spa-nextjs',
        env: Config.public.misc.environment,
        version: process.env.NEXT_PUBLIC_APP_VERSION,
        sampleRate: Number(Config.public.misc.ddSampleRate || 0),
        premiumSampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        trackViewsManually: true,
        beforeSend: (event) => {
          if (event.type === 'view') {
            // eslint-disable-next-line no-param-reassign
            event.view.url = window.location.href;
            // eslint-disable-next-line no-param-reassign
            event.view.referrer = document.referrer();
          }
        },
      });

      datadogLogs.init({
        clientToken: Config.public.misc.ddClientToken,
        site: 'datadoghq.com',
        service: 'ipsy-spa-nextjs',
        env: Config.public.misc.environment,
        forwardErrorsToLogs: true,
        version: process.env.NEXT_PUBLIC_APP_VERSION,
        sessionSampleRate: 100,
        telemetrySampleRate: 0,
      });
      datadogLogs.setGlobalContextProperty(
        'terrain',
        Config.public.misc.environment
      );

      if (user) {
        datadogRum.setUser({ id: user.id });
        datadogLogs.setUser({ id: user.id });
      }

      datadogRum.startSessionReplayRecording();
      datadogRum.startView({
        name: getPathGroup(window.location.pathname),
        service: getTeamName(window.location.pathname),
      });

      mobileBridgeService.initialize();
      facebookSdkService.initialize();

      fireDeferredEvents(router);

      if (clientConfigurationService.isInNativeApp() && user) {
        mobileBridgeService.sendMessageToNativeApp('USER_INFO', {
          user_id: user.id,
          email: user.email,
        });
      }

      requestPlatformInformation();
      forterService.setForterMobileUidCookies();

      initialized.current = true;
    }
  }, [router, user]);

  return initialized.current;
};

const ServiceInitialization: FC<Props> = ({ user }) => {
  useInitialize(user);
  return null;
};

export default ServiceInitialization;
