export const CANCELLATION_STATE_KEY = 'CancellationNavigation';
export const LEGACY_CANCELLATION_PATH =
  '/account/manageSubscription/stopMembership';

// Logging messages
export const LOG_PREFIX = '[Cancellation]';
export const CANCELLATION_DISABLED_ON_GVS = `${LOG_PREFIX} Not enabled by PUBLIC_LITE_CANCELLATION_FLOW on GVS. Redirecting to Account page`;
export const NO_CANCELLATION_STEPS = `${LOG_PREFIX} There are no available steps in this cancellation flow. Redirecting to Account page`;
export const LOGIN_CONFIRMATION_DELTA_NOT_FULFILLED = `${LOG_PREFIX} Last login happened before the accepted interval. Redirecting to login confirmation`;
export const COULD_NOT_FIND_CANCELLING_PROGRAM = `${LOG_PREFIX} Could not get Subscription Program being cancelled`;
export const CANCELLATION_DATA_NOT_PROPERLY_SETUP = `${LOG_PREFIX} Could not find Cancellation Navigation data. Redirecting to /cancellation`;
export const GENERIC_ERROR =
  'Something went wrong with your cancel. Please contact IPSY care.';

/**
 * Step ID of the mandatory initializing step of the flow.
 */
export const INITIAL_STEP = 'INIT';
/**
 * Step ID of the mandatory finalizing step of the flow.
 */
export const FINAL_STEP = 'CANCEL_CONFIRMATION';

export const UNAUTHORIZED_ACCESS_TO_CANCELLATION_STEP = (
  identifier?: string
): string =>
  `${LOG_PREFIX} Unauthorized access to ${identifier} step. Redirecting to login confirmation.`;
export const WRONG_CANCELLATION_STEP = (
  landed: string,
  expected: string
): string =>
  `${LOG_PREFIX} Landed on ${landed} but cancellation data points to ${expected}. Redirecting.`;
export const ATTEMPT_TO_JUMP_TO_ILLEGAL_STEP = (
  stepId: string,
  allowedSteps: string[]
): string =>
  `${LOG_PREFIX} Attempt to jump to ${stepId} but the allowed steps are ${allowedSteps}`;

export const EVERY_OTHER_MONTH_CADENCE = 2;
export const CREDIT_FOR_MONTH_EXP = 1;
