import { getSegmentAnalyticsClient } from '@bfa/capi';
import { clientLogging } from '@bfa/nextjs-common/services';
import { useEffect } from 'react';

/**
 * send Pageview event to segment
 * @param path of the page
 * @param payload with some relevant data of the page
 * @returns page path
 */
export function segmentPage(path: string, payload?: any): string {
  const properties = { payload: payload };
  try {
    getSegmentAnalyticsClient().page(path, properties);
  } catch (err) {
    clientLogging.logError(Error(`Failed to send pageview ${path} ${err}`));
  }
  return path;
}

export function useSegmentPageConditionally(
  shouldSend: boolean,
  path: string,
  payload?: any
): void {
  useEffect(() => {
    segmentPageViewConditionally(shouldSend, path, payload);
  }, [shouldSend]);
}

export function segmentPageViewConditionally(
  shouldSend: boolean,
  path: string,
  payload?: any
): void {
  if (shouldSend) {
    segmentPage(path, payload);
  }
}

const programSplashPageRegularExpressions: RegExp[] = [
  /\/glambag\/\D+-\d{4}$/,
  /\/boxycharm\/\D+-\d{4}$/,
  /\/iconbox\/\D+-\d{4}$/,
  /\/refreshments\/\D+-\d{4}$/,
  /\/blog\/.+$/,
  /\/blog.+$/,
];

export function isSegmentWhiteListPage(path: string): boolean {
  for (const pageRegularExpression of programSplashPageRegularExpressions) {
    if (pageRegularExpression.test(path)) {
      return true;
    }
  }
  return false;
}
